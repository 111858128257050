<template>
	<div style="position: relative;">
		<header id="header" class="header-nav">
			<!-- container  -->
			<div class="header-size header-top">
				<!-- <div class="d-flex align-items-center justify-content-between">
					
				</div> -->
				<div class="logo-not-logged" style="position: absolute;right: 6px;">
					<div class="dropdown dropdown2" style="margin-right: 6px;position: relative;">
						<!--  :class="hasLang?'theme-color':''" -->
						<a class="nav-link scrollto nav-link2" @click.stop="changeLang()">
							<img class="dropdown-la-img" src="../assets/img/share/la.png" />
							{{ language=='en'?'EN':'简体' }}
							<img class="dropdown-la-img" src="../assets/img/share/down.png" />
							<!-- <i class="bi bi-chevron-down" v-if="!hasLang"></i> -->
							<!-- <i class="bi bi-chevron-up" v-else></i> -->
						</a>
						<div class="dropdown-active dropdown-box-ul" v-show="isLang" style="position: absolute;top: 44px;right: -5px;z-index: 1;background: rgba(40, 40, 40, 1);">
							<li class="dropdown-li cursor-1" :class="language=='zh'?'dropdown-li2':''"
								@click.stop="changeLanguage(0)">
								<div class="dropdown-libox" :class="language=='简体'?'dropdown-li2':''">简体</div>
							</li>
							<li class="dropdown-li cursor-1" :class="language=='en'?'dropdown-li2':''"
								@click.stop="changeLanguage(1)">
								<div class="dropdown-box" :class="language=='en'?'dropdown-li2':''">EN</div>
							</li>
						</div>
					</div>
				</div>
			</div>
		</header>
		<div class="header-h"></div>
	</div>
</template>

<script>
	export default {
		name: 'header2',
		components: {
			
		},
		props: {},
		data() {
			return{
				language: 'zh',
				hasLang:false,
				isLang:false,
			}
		},
		mounted() {
			let lang = localStorage.getItem('lang') || 'zh';
			let langlocale = (lang === 'en' ? 'en' : 'zh');
			this.language = langlocale;
		},
		methods:{
			moveLang(){
				this.hasLang=true;
			},
			leaveLang(){
				this.hasLang=false;
			},
			changeLang(){
				this.hasLang=true;
				this.isLang=!this.isLang;	
			},
			changeLanguage(index) {
				let lang = "zh";
				if (index == 0) {
					this.language = 'zh';
					lang = "zh";
				} else {
					this.language = 'en';
					lang = "en";
				}
				localStorage.setItem('lang', lang);
				this.$i18n.locale = lang;
				window.location.reload();
			},
			tohome(){
				this.$router.push('/');
			},
			toLogin(){
				this.$nextTick(()=>{
					this.$refs.login.dialogFormVisible=true;
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.nav-link2{
		display: flex;
		align-items: center;

		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 18px;
		color: rgba(255,255,255,0.85) !important;
		line-height: 36px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
	.header-nav{
		display: flex;
		box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
	}
	.header-top{
		display: flex;
		justify-content: space-between !important;
	}
	.logo-welcome{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: #333333;
		line-height: 23px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		border-left: 1px solid #E5E5E5;
		padding-left: 18px;
		margin-left: 18px;
	}
	.logo-not-logged{
		display: flex;
		align-items: center;
		
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 16px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
	.navbar-h5 li{
		margin: 0 20px;
		padding: 0 !important;
		border-bottom: 1px solid rgba(243, 243, 243, 1);
		.dropdown-left{
			padding: 10px 0!important;
		}
	}
	.navbar-h5 .dropdown-left{
		padding: 10px 0!important;
	}
	.navbar-h5 a{
		padding: 10px 0 !important;
	}
	
	.pro-h5-list{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 28px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		padding: 6px 0;
	}
	.dropdown-li{
		margin: 0!important;
	}
	
	.nav-flex-1{
		flex: 1;
		// margin-left: 20px;
		display: flex;
		justify-content: flex-end;
	}
	.dropdown-left{
		margin-left: 20px;
	}
	.nav-flex-2{
		flex: 1;
		display: flex;
		justify-content: flex-start;
	}
	.logo-name {
		width: 88px;
		height: 36px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: bold;
		font-size: 24px;
		color: #333333;
		line-height: 28px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		margin-left: 8px;
	}
	
	.navbar-right {
		margin-left: 3.5rem;
	}
	
	.login {
		padding: 6px 18px;
		height: 36px;
		// padding: 6px 1.125rem;
		// height: 2.25rem;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #D9D9D9;
	
		display: flex;
		align-items: center;
		justify-content: center;
	
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		line-height: 19px;
		text-align: left;
		font-style: normal;
		text-transform: none;
		cursor: pointer;
	}
	
	.dropdown-box-ul {
		padding: 8px 0;
		box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.15);
		border-radius: 4px 4px 4px 4px;
	}
	
	.dropdown-la-img {
		width: 22px;
		height: 22px;
		margin-right: 4px;
	}
	
	.dropdown2 ul {
		top: 45px !important;
		left: 0 !important;
	}
	
	.dropdown-li {
		width: 136px;
		height: 53px;
	
		display: flex;
		align-items: center;
		justify-content: center;
		
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 16px;
		color: rgba(255,255,255,0.85);
		line-height: 36px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
	
	.dropdown-box {
		width: 136px;
		height: 53px;
		background: rgba(40, 40, 40, 1);
		border-radius: 0px 0px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 16px;
		color: rgba(255, 255, 255, 0.85);
		line-height: 16px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
	
	/* .dropdown-li:hover{
		background: #E6F4F4;
		
	} */
	.dropdown-li2 {
		background: rgba(63, 63, 63, 1);
		color: rgba(16, 177, 167, 1) !important;
	
	}
	
	.dropdown-active-pro-view {
		width: 100%;
		height: 300px;
		background-color: #FFFFFF;
	
		position: fixed;
		left: 0;
		right: 0;
		top: 74px;
		z-index: 99;
		padding: 32px 0 44px;
	
		.pro-list-box {
			display: flex;
			align-items: center;
			justify-content: center;
	
			.pro-list-box-1 {
				height: 35px;
				border-radius: 4px 4px 4px 4px;
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 18px;
				color: #333333;
				line-height: 21px;
				text-align: center;
				font-style: normal;
				text-transform: none;
	
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 60px;
				padding: 4px 16px;
			}
	
			.pro-list-box-2 {
				background: #10B1A7;
				color: #FFF;
			}
		}
	
		.pro-list-box2 {
			height: 157px;
			margin-top: 32px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
	
			.pro-list-box-1-left {
				position: absolute;
				left: 0px;
			}
	
			.pro-list-box-1-right {
				position: absolute;
				right: 0px;
			}
	
			.pro-list-box-1-icon {
				width: 60px;
				height: 60px;
	
				img {
					width: 60px;
					height: 60px;
				}
			}
			.pro-list-box-1-list-center{
				justify-content: center;
			}
			.pro-list-box-1-list {
				display: flex;
				padding: 0 100px;
				overflow: hidden;
	
				.pro-goods-box {
					display: flex;
					align-items: center;
					flex-direction: column;
					margin-right: 41px;
	
					.pro-goods {
						width: 180px;
						height: 120px;
						border-radius: 0px 0px 0px 0px;
						img{
							width: 180px;
							height: 120px;
						}
					}
	
					.pro-goods-name {
						font-family: Source Han Sans CN, Source Han Sans CN;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						line-height: 16px;
						text-align: left;
						font-style: normal;
						text-transform: none;
						margin-top: 16px;
					}
				}
			}
		}
	}
	
	.dropdown-avatar-img{
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}
	.avatar-active{
		color: #10B1A7;
	}
	.avatar-box-view{
		display: block;
		position: absolute;
		left: 28px;
		top: calc(135%);
		margin: 0;
		z-index: 99;
		background: #fff;
		box-shadow: 0px 4px 32px 0px rgba(0,0,0,0.15);
		transition: 0.3s;
		border-radius: 4px 4px 4px 4px;
		padding: 8px 0;
	}
	.dropdown-li-top{
		border-top: 1px solid #E5E5E5;
	}
</style>