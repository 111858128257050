<template>
	<div id="app">
		<header2></header2>
		<section class="section">
			<router-view />
		</section>
	</div>
</template>
<script>
	import header2 from '@/components/header2.vue'
	export default {
		name: '',
		components: {
			header2
		},
		data() {
			return {
				isActive: false,
				haslogin: true,
			}
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
			
		},
	}
</script>
<style>
	.section{
		background: #000000 !important;
		height: calc( 100vh - 36px );
	}
</style>