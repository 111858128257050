import axios from 'axios';
import { baseUrl } from "../common/baseUrl.js";
//import md5 from "../common/md5.js";
//const md5 = require('../common/md5.js');
import md5 from "js-md5";
//import https from 'https';
//import { Message, Spin, Modal } from 'vie//w-design';
// import Storage from './storage';
// import router from '../router/index.js';
// import store from '../vuex/store';
// import { handleRefreshToken } from '@/api/index';

// import {v4 as uuidv4 } from 'uuid';

const service = axios.create({
	timeout: 8000,
	baseURL: baseUrl
});

service.interceptors.request.use(config => {
		return config;
},err => {
	//Message.error("请求超时");
	return Promise.reject(err);
});

service.interceptors.response.use(response => {
	const data = response.data;
	return data;
},
async error => {
	if (error.response) {}
});

export const getRequest = (url, params) => {
  let accessToken = '';//getStore("accessToken");
  let lang=localStorage.getItem('lang') || 'zh';
  lang=(lang=='en'?'en_US':'zh_CN');
  let deviceIdStorage=localStorage.getItem('deviceId');
  let  deviceId='';
  if(!deviceIdStorage){
	 deviceId="WEB"+Math.floor(10000000 + Math.random() * 900000);//设备唯一值
	 localStorage.setItem('deviceId', deviceId);
	 deviceIdStorage=deviceId;
  }
  const d=3;
  const l=lang;//l=en_US   zh_CN
  const ts=parseInt(new Date().getTime() / 1000) + '';//new Date().getTime()/1000;//时间戳
  const n=Math.floor(100000 + Math.random() * 900000);//6位随机数
  const f=deviceIdStorage;
  const tk=localStorage.getItem('loginState') || '';//token
  
  let dataObj={};
  dataObj.d=d;
  dataObj.l=l;
  dataObj.ts=ts;
  dataObj.n=n;
  dataObj.f=deviceIdStorage?deviceIdStorage:f;
  dataObj.tk=tk;
  let signMd5 = paramsSort(dataObj);
  let forinstring = "";
  for (let key in signMd5) {
  		forinstring += "&" + key + "=" + encodeURI(signMd5[key]).replace(/\=/g, "%3D");
  }
  forinstring+='&key=Oq7W8cj7HbwqSfGlysxlYstp6Gh';
  forinstring=forinstring.slice(1);
  signMd5=md5(forinstring).toUpperCase()
  signMd5=signMd5.toLocaleLowerCase();
  let data = {
    method: "get",
    url: `${url}`,
    params: params,
    headers: {
      "Content-Type": "application/json",
      //accessToken: accessToken,
	  a:'Record-20240530',
	  c:'BB',
	  os:'Bro',
      d:d,
      l:l,
      ts:ts,
      n:n,
      f:f,
      tk:tk,
      sign:signMd5,
    },
  };
  return service(data);
};

export const postRequest = (url, params, headers) => {
	url=decodeURIComponent(url);
  let accessToken = '';//getStore("accessToken");
  let lang=localStorage.getItem('lang') || 'zh';
  lang=(lang=='en'?'en_US':'zh_CN');
  let deviceIdStorage=localStorage.getItem('deviceId');
  let  deviceId='';
  if(!deviceIdStorage){
	 deviceId="WEB"+Math.floor(10000000 + Math.random() * 900000);//设备唯一值
	 localStorage.setItem('deviceId', deviceId);
	 deviceIdStorage=deviceId;
  }
  const d=3;
  const l=lang;//l=en_US   zh_CN
  const ts=parseInt(new Date().getTime() / 1000) + '';//new Date().getTime()/1000;//时间戳
  const n=Math.floor(100000 + Math.random() * 900000);//6位随机数
  const f=deviceIdStorage;
  const tk=localStorage.getItem('loginState') || '';//token
  
  let dataObj={};
  dataObj.d=d;
  dataObj.l=l;
  dataObj.ts=ts;
  dataObj.n=n;
  dataObj.f=deviceIdStorage?deviceIdStorage:f;
  dataObj.tk=tk;
  let signMd5 = paramsSort(dataObj);
  let forinstring = "";
  for (let key in signMd5) {
		forinstring += "&" + key + "=" + encodeURI(signMd5[key]).replace(/\=/g, "%3D");
  }
  forinstring+='&key=Oq7W8cj7HbwqSfGlysxlYstp6Gh';
  forinstring=forinstring.slice(1);
  signMd5=md5(forinstring).toUpperCase()
  signMd5=signMd5.toLocaleLowerCase();
  return service({
    method: "post",
    url: `${url}`,
    data: params,
    headers: {
      "Content-Type": "application/json",
      //accessToken: accessToken,
	  a:'Record-20240530',
	  c:'BB',
	  os:'Bro',
	  d:d,
	  l:l,
	  ts:ts,
	  n:n,
	  f:f,
	  tk:tk,
	  sign:signMd5,
      ...headers
    }
  });
};


export const  sendConcurrentRequests = (url,id, params, headers) => {
	var data3=[];
	const dataObj=getSign(url,headers);
	const params0 = {
		"mid": id,
		"page_num": 1,
		"page_size": 100
	}
	const requests = [
    service({
      method: "post",
      url: `${url}`,
      data: params0,
      headers: {
        "Content-Type": "application/json",
        //accessToken: accessToken,
    	  a:'Record-20240530',
    	  c:'BB',
    	  os:'Bro',
    	  d:dataObj.d,
    	  l:dataObj.l,
    	  ts:dataObj.ts,
    	  n:dataObj.n,
    	  f:dataObj.f,
    	  tk:dataObj.tk,
    	  sign:dataObj.signMd5,
        ...headers
      }
    }),
  ];
 
  return Promise.all(requests).then(responses => {
      // 所有请求都成功了，处理响应数据
      let data1 = responses[0].data;
      return data1;
    }).then(res=>{
		//console.log(res)
		var count=Math.ceil(res.total/100);
		const requests2=[]
		//console.log(count)
		for(var i=2;i<=count;i++){
			const params0 = {
				"mid": id,
				"page_num": i,
				"page_size": 100
			}
			const dataObj2=getSign(url,headers);
			requests2.push(service({
			  method: "post",
			  url: `${url}`,
			  data: params0,
			  headers: {
			    "Content-Type": "application/json",
			    //accessToken: accessToken,
				  a:'Record-20240530',
				  c:'BB',
				  os:'Bro',
				  d:dataObj2.d,
				  l:dataObj2.l,
				  ts:dataObj2.ts,
				  n:dataObj2.n,
				  f:dataObj2.f,
				  tk:dataObj2.tk,
				  sign:dataObj2.signMd5,
			    ...headers
			  }
			}));
		}
		return Promise.all(requests2).then(responses2 => {
			data3=data3.concat(res.data_list);
			for(var i=0;i<requests2.length;i++){
				const array = responses2[i].data;
				data3=data3.concat(array.data_list);
			}
			return Promise.resolve(data3)
		});
	}).catch(error => {
      console.error('An error occurred during concurrent requests:', error);
      throw error;
    });
};



function getSign(url,headers){
	url=decodeURIComponent(url);
	let accessToken = '';//getStore("accessToken");
	let lang=localStorage.getItem('lang') || 'zh';
	lang=(lang=='en'?'en_US':'zh_CN');
	let deviceIdStorage=localStorage.getItem('deviceId');
	let  deviceId='';
	if(!deviceIdStorage){
		 deviceId="WEB"+Math.floor(10000000 + Math.random() * 900000);//设备唯一值
		 localStorage.setItem('deviceId', deviceId);
		 deviceIdStorage=deviceId;
	}
	const d=3;
	const l=lang;//l=en_US   zh_CN
	const ts=parseInt(new Date().getTime() / 1000) + '';//new Date().getTime()/1000;//时间戳
	const n=Math.floor(100000 + Math.random() * 900000);//6位随机数
	const f=deviceIdStorage;
	const tk=localStorage.getItem('loginState') || '';//token
	
	let dataObj={};
	dataObj.d=d;
	dataObj.l=l;
	dataObj.ts=ts;
	dataObj.n=n;
	dataObj.f=deviceIdStorage?deviceIdStorage:f;
	dataObj.tk=tk;
	let signMd5 = paramsSort(dataObj);
	let forinstring = "";
	for (let key in signMd5) {
		forinstring += "&" + key + "=" + encodeURI(signMd5[key]).replace(/\=/g, "%3D");
	}
	forinstring+='&key=Oq7W8cj7HbwqSfGlysxlYstp6Gh';
	forinstring=forinstring.slice(1);
	signMd5=md5(forinstring).toUpperCase()
	signMd5=signMd5.toLocaleLowerCase();
	dataObj.signMd5=signMd5;
	return dataObj;
}

//参数排序
function paramsSort(o) {
	let sorted = {},
	keys = Object.keys(o);
	keys.sort();
	keys.forEach((key) => {
		sorted[key] = o[key];
	})
	return sorted;
}