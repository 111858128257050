<template>
  <div class="audio-player">
	<!-- <div style="display: flex;align-items: center;padding: 10px 20px 25px;">
		<div v-show="showProgressBar" 
			ref="audioProgressWrap"
			class="audio__progress-wrap"
			@click.stop="handleClickProgressWrap">
			  <div
				ref="audioProgress"
				class="audio__progress"
				:style="{
				  backgroundColor: themeColor,
				}"
			  />
			  <div
				ref="audioProgressPoint"
				class="audio__progress-point"
				:style="{
				  backgroundColor: themeColor,
				  boxShadow: `0 0 10px 0 ${themeColor}`,
				}"
				@panstart="handleProgressPanstart"
				@panend="handleProgressPanend"
				@panmove="handleProgressPanmove"
			  />
		</div>
	</div> -->
	<div class="el-slider-view" style="padding: 10px 20px 25px;">
	    <el-slider v-model="value1":step="0.000001" :show-tooltip="false" @change="intputChange" :max="maxValue"></el-slider>
	</div>
	<div class="page-header-nav">
		<div class="play-time">
			<span class="time-1">{{ currentTimeFormatted }}</span>/<span class="time-2">{{ durationFormattedTime }}</span>
		</div>
		<div class="play-but">
			<div class="play-but-1">
				<img v-if="!isPlaying && showPlayButton" src="../../../src/assets/img/share/s12.png"  @click.stop="play()"/>
				<img v-else-if="showPlayButton"  src="../../../src/assets/img/share/s1.png"  @click.stop="pause"/>
			</div>
			<div class="play-but-1" @click="playReduce()">
				<img src="../../../src/assets/img/share/s2.png" />
			</div>
			<div class="play-but-1" @click="playPush()">
				<img src="../../../src/assets/img/share/s3.png" />
			</div>
		</div>
	</div>
	
    <audio
      ref="audio"
      class="audio-player__audio"
      :src="audioList[currentPlayIndex]"
      v-bind="$attrs"
      @ended="onEnded"
      @timeupdate="onTimeUpdate"
      @loadedmetadata="onLoadedmetadata"
    >

    </audio>
  </div>
</template>

<script>
import Core from '@any-touch/core'
import Pan from '@any-touch/pan'
import { getAudioFileUpdate } from "../../../common/api";
	
export default {
  name: 'AudioPlayer',

  inheritAttrs: false,

  props: {
    // 音频播放列表
    audioList: {
      default: null,
      type: Array,
    },

    // 是否显示播放按钮
    showPlayButton: {
      default: true,
      type: Boolean,
    },

    // 是否显示上一首按钮
    showPrevButton: {
      default: true,
      type: Boolean,
    },

    // 是否显示下一首按钮
    showNextButton: {
      default: true,
      type: Boolean,
    },

    // 是否显示音量按钮
    showVolumeButton: {
      default: true,
      type: Boolean,
    },

    // 显示进度条
    showProgressBar: {
      default: true,
      type: Boolean,
    },

    // 播放前的回调函数
    beforePlay: {
      default: null,
      type: Function,
    },

    // 上一首前的回调函数
    beforePrev: {
      default: null,
      type: Function,
    },

    // 下一首前的回调函数
    beforeNext: {
      default: null,
      type: Function,
    },

    // 是否列表循环播放
    isLoop: {
      type: Boolean,
      default: true,
    },

    // 是否自动播放下一首
    isAutoPlayNext: {
      type: Boolean,
      default: false,
    },

    // 进度更新间隔
    progressInterval: {
      default: 500,
      type: Number,
    },

    // 是否显示倍速播放速率
    showPlaybackRate: {
      type: Boolean,
      default: true,
    },

    // 是否显示播放时的 loading
    showPlayLoading: {
      type: Boolean,
      default: true,
    },

    playbackRates: {
      type: Array,
      default: () => [1.5, 1.25,1.0, 0.75, 0.5],
    },

    themeColor: {
      type: String,
      default: '#10B1A7',
    },

    // 是否禁用进度条可拖拽功能
    disabledProgressDrag: {
      type: Boolean,
      default: false,
    },

    // 是否禁用进度条可点击功能
    disabledProgressClick: {
      type: Boolean,
      default: false,
    },
	marks:{
		type: Array,
		default: () => [],
	}
  },

  emits: [
    'pause',
    'play-prev',
    'play-next',
    'timeupdate',
    'loadedmetadata',
    'ended',
    'progress-start',
    'progress-end',
    'progress-move',
    'progress-click',
    'playing',
    'play',
    'play-error',
  ],

  data() {
    return {
      isIOS: /iPhone|iPad|iPod/i.test(window.navigator.userAgent), // 是否是IOS设备
      isPlaying: false, // 音频是否正在播放
      isDragging: false, // 是否正在拖拽音频进度
      isDraggingVolume: false, // 是否正在拖拽音量进度
      isShowErrorMessage: false,
      isLoading: false,
      isShowVolume: false,
      isShowRates: false,
      timer: null,
      noticeMessage: '',
      duration: '', // 音频持续时间
      currentPlayIndex: 0, // 当前播放的音频位置索引
      currentTime: 0, // 音频当前播放时间
      currentVolume: 1, // 当前音量
      playbackRate: 1.0, // 当前播放速率
      at: null,
	  signState:false,
	  signIndex:-1,
	  signList:[],
	  durationFormattedTime:'00:00:00',
	  
	  maxValue:100,
	  value1: 0,
	  currentIndex:1,
    }
  },

  computed: {
    currentTimeFormatted() {
		//console.log(this.currentTime)
      return this.currentTime ? this.formatTime(this.currentTime) : '00:00:00'
    },
    durationFormatted() {
		//console.log(this.duration);
		//console.log(this.duration ? this.formatTime2(this.duration*1000) : '00:00:00')
		return (this.duration) ? this.formatTime2(this.duration*1000) : '00:00:00'
    },
  },

  mounted() {
    this.at = new Core(this.$el, { preventDefault: false })
    this.at.use(Pan)
	//this.$refs.audioProgressPointSign.style.left ='10px'
  },

  beforeUnmount() {
    this.at.destroy()
    this.pause()
  },

  methods: {
	intputChange(e){
	  console.log(e)
	  this.value1=e; 
	  this.currentTime=e;
	  this.$refs.audio.currentTime=e;

	  this.play();
	  this.$emit('progress-move',this.currentTime)
	},
	newTime(time){
	   this.$refs.audio.currentTime=time;
	   this.play();
	},  
	mousemove(){
		//this.isShowRates=true;    
	},
	mouseleave(){
		// setTimeout(()=>{
		// 	this.isShowRates=false;  
		// },2000)
		
	},
	// Del(item){
	// 	this.$emit('Del', item.id)
	// },
	// handleSetPlaySign2(markTime){
	// 	this.$refs.audio.currentTime=markTime/1000;
	// 	this.play();
	// },
	playReduce(){
		console.log(this.currentTime)
		if(this.currentTime>15){
			this.$refs.audio.currentTime=this.currentTime-15;
		}else{
			this.$refs.audio.currentTime=0;
		}
	},
	playPush(){
		if(this.currentTime+15>=this.duration){
			this.$refs.audio.currentTime=this.duration;
		}else{
			this.$refs.audio.currentTime=this.currentTime+15;
		}
	},
	playSign(){
		//console.log(this.$parent)
		this.$parent.dialogAccount=!this.$parent.dialogAccount;
		//this.dialogAccount=!this.dialogAccount;
	},
	playSign2(){
		if(this.marks.length==0){
			return
		}
		this.signState=!this.signState;
	},
    handleVolumeIconTouchstart() {
      this.isShowVolume = !this.isShowVolume
    },

    handleVolumePanmove(event) {
      let playVolumeWrapRect = this.$refs.playVolumeWrap.getBoundingClientRect()
      let pageY = event.y
      let offsetTop
      let volume

      offsetTop = Math.round(playVolumeWrapRect.bottom - pageY)
      volume = offsetTop / this.$refs.playVolumeWrap.offsetHeight
      volume = Math.min(volume, 1)
      volume = Math.max(volume, 0)
      this.$refs.audio.volume = volume
      this.currentVolume = volume
    },

    handleVolumePanend() {
      this.isShowVolume = false
    },

    // 设定播放速率
    handleSetPlaybackRate(rate) {
		//刷新率
	  //this.progressInterval=1000;//(1/rate)*1000;
	 // console.log(this.progressInterval)
      this.playbackRate = +rate
      this.$refs.audio.playbackRate = +rate
      this.isShowRates = false
    },

    // 错误消息处理
    handleShowErrorMessage(opts = {}) {
      this.noticeMessage = opts.message
      this.isShowErrorMessage = true

      window.setTimeout(() => {
        this.isShowErrorMessage = false
      }, opts.duration || 3000)
    },

    // 当媒介元素的持续时间以及其它媒介已加载数据时运行脚本
    onLoadedmetadata(event) {
		this.maxValue=Number(this.$refs.audio.duration);
		//console.log(this.$refs.audio.duration)
		//setTimeout(()=>{
			//this.duration = this.$refs.audio.duration;
		//},500)
		this.duration = this.$refs.audio.duration;
		this.durationFormattedTime=(this.$refs.audio.duration) ? this.formatTime2(this.$refs.audio.duration*1000) : '00:00:00'
		this.$emit('loadedmetadata', event)
    },

    // 当前的播放位置发送改变时触发
    onTimeUpdate(event) {
		this.value1=Number(event.target.currentTime);
		//console.log(event.target.currentTime)
		this.$emit('timeupdate', event)
    },

    // 格式化秒为分
    formatTime(second) {
		const hours = Math.floor(second / 3600).toString().padStart(2, '0');
	    const minutes = Math.floor((second % 3600) / 60).toString().padStart(2, '0');
	    const remainingSeconds = (second % 60).toString().padStart(2, '0');
	    //console.log(hours + ":" + minutes + ":" + Number(remainingSeconds).toFixed(0))
	    return hours + ':'+minutes + ':' + Number(remainingSeconds).toFixed(0).padStart(2, '0');
	   //    // 将秒数除以60，然后下舍入，既得到分钟数
	   //    let hour=0;
		  // let minute=0;
		  // hour = Math.floor(second / 60/60)
	   //    minute = Math.floor(second / 60)
		  // if(minute>=60){
			 //  minute=minute/60;
			 //  minute=(minute - Math.floor(minute))*60
			 //  minute= Math.floor(minute)
		  // }
	   //    // 取得秒%60的余数，既得到秒数
	   //    second = Math.ceil(second % 60)
	   //    // 将变量转换为字符串
		  // //hour += ''
	   //    minute += ''
	   //    second += ''
	   //    // 如果只有一位数，前面增加一个0
		  // hour = hour<10 ? '0' + hour : hour
	   //    minute = minute.length === 1 ? '0' + minute : minute
	   //    second = second.length === 1 ? '0' + second : second==60?0:second
		  // console.log(hour + ':'+minute + ':' + second)
		  //return hour + ':'+minute + ':' + second
    },
	formatTime2(value){
		if (value === 0 || value < 1000) return `00:00:00`;
		var timestamp = parseInt(value) / 1000; // 毫秒转秒
		// 小时取余数
		const remainder = timestamp % 3600
		// 时、分、秒
		let hour=0;
		let minute=0;
		let second=0;
		if (remainder === 0) { // 整除 小时
		    hour = parseInt(timestamp / 3600);
		} else {
		    hour = parseInt(timestamp / 3600);
		    let remainderMinute = remainder % 60;
		    if (remainderMinute === 0) { // 整除 分钟
		        minute = parseInt(remainder / 60);
		    } else {
		        minute = parseInt(remainder / 60);
		        second = Math.ceil(remainderMinute);
		    }
		}
		let formattedTime = `${hour>=10?hour:'0'+hour}:${minute>=10?minute:'0'+minute}:${second>=10?second:'0'+second}`;
		return formattedTime;
	},
    // 音频播放完毕
    onEnded(event) {
      window.setTimeout(() => {
        this.pause()
        this.$emit('ended', event)

        if (this.isLoop && this.isAutoPlayNext) {
          this.playNext()
        }
      }, 1000)
    },

   //  handleProgressPanstart(event) {
   //    if (this.disabledProgressDrag) return
   //    this.isDragging = true
   //    this.$emit('progress-start', event)
   //  },

   //  handleProgressPanend(event) {
   //    if (this.disabledProgressDrag) return

   //    this.$refs.audio.currentTime = this.currentTime
   //    this.play()
   //    this.isDragging = false
   //    //this.$emit('progress-end', event)
	  // this.$emit('progress-move', event,this.currentTime)
   //  },

   //  handleProgressPanmove(event) {
   //    if (this.disabledProgressDrag) return

   //    let pageX = event.x
   //    let bcr = event.target.getBoundingClientRect()
   //    let targetLeft = parseInt(getComputedStyle(event.target).left)
   //    let offsetLeft = targetLeft + (pageX - bcr.left)

   //    offsetLeft = Math.min(
   //      offsetLeft,
   //      this.$refs.audioProgressWrap.offsetWidth,
   //    )
   //    offsetLeft = Math.max(offsetLeft, 0)
   //    // 设置点点位置
   //    this.setPointPosition(offsetLeft)
   //    // 设置进度条
   //    this.$refs.audioProgress.style.width = offsetLeft + 'px'
   //    // 设置当前时间
   //    this.currentTime =
   //      (offsetLeft / this.$refs.audioProgressWrap.offsetWidth) * this.duration
   //    this.$emit('progress-move', event,this.currentTime)
   //  },

    // 初始化音频进度的点击逻辑
    handleClickProgressWrap(event) {
      if (this.disabledProgressClick) return

      let target = event.target
      let offsetX = event.offsetX

      if (target === this.$refs.audioProgressPoint) {
        return
      }

      // 设置当前播放位置
      this.currentTime =
        (offsetX / this.$refs.audioProgressWrap.offsetWidth) * this.duration
      this.$refs.audio.currentTime = this.currentTime
      // 设置点点位置
      this.setPointPosition(offsetX)
      // 设置进度条
      this.$refs.audioProgress.style.width = offsetX + 'px'
      this.play()
      //this.$emit('progress-click', event)
	  this.$emit('progress-move', event,this.currentTime)
    },

    // 设置点点位置
    setPointPosition(offsetLeft) {
      this.$refs.audioProgressPoint.style.left =
        offsetLeft - this.$refs.audioProgressPoint.offsetWidth / 2 + 'px'
    },

    // 播放中
    playing() {
      // 正在拖拽进度
      if (this.isDragging) {
        return
      }

      //let offsetLeft =(this.$refs.audio.currentTime / this.$refs.audio.duration) *this.$refs.audioProgressWrap.offsetWidth;

      this.currentTime = this.$refs.audio.currentTime
      //this.$refs.audioProgress.style.width = offsetLeft + 'px' // 设置播放进度条
      //this.setPointPosition(offsetLeft) // 设置播放进度拖拽点位置
	  //console.log(this.currentTime)
      this.$emit('playing',this.currentTime)
    },

    // 开始播放
    play() {
      this.isLoading = true
      let handlePlay = () => {
        this.$refs.audio.play().then(() => {
            this.$nextTick(() => {
              if (this.timer) {
                this.currentTime = this.$refs.audio.currentTime
              } else {
                this.timer = window.setInterval(
                  this.playing,
                  this.progressInterval,
                )
              }

              this.isPlaying = true
              this.isLoading = false
              this.$refs.audio.playbackRate = this.playbackRate
            })
            this.$emit('play')
          }).catch((data) => {
            this.handleShowErrorMessage({
              message: data.message,
            })
            // Failed to load because no supported source was found.
            if (data.code === 9) {
              if (this.isAutoPlayNext) {
                window.setTimeout(() => {
                  this.playNext()
                }, 3000)
              }
            }

            this.isLoading = false
            this.$emit('play-error', data)
          })
      }
		console.log(window.navigator.userAgent)
      // 解决 iOS 异步请求后无法播放
      // if (this.isIOS) {
      //   console.log('为了解决 iOS 设备接口异步请求后出现无法播放问题，请无视 The play() request was interrupted by a call to pause() 错误',)
      //   this.$refs.audio.play()
      //   this.$refs.audio.pause()
      // }

      if (this.beforePlay) {
        this.beforePlay((state) => {
          if (state !== false) {
            handlePlay()
          }
        })
        return
      }

      handlePlay()
    },

    // 暂停播放
    pause() {
      this.$refs.audio.pause()
      this.$nextTick(() => {
        this.clearTimer()
        this.isPlaying = false
        this.$emit('pause')
      })
    },

    // 播放上一首
    playPrev() {
      if (this.currentPlayIndex <= 0 && !this.isLoop) {
        // 无上一首了
        return
      }

      this.clearTimer()

      let handlePrev = () => {
        if (this.currentPlayIndex <= 0 && this.isLoop) {
          // 列表循环
          this.currentPlayIndex = this.audioList.length - 1
        } else {
          this.currentPlayIndex--
        }

        this.$nextTick(() => {
          this.play()
          this.$emit('play-prev')
        })
      }

      if (this.beforePrev) {
        this.beforePrev((state) => {
          if (state !== false) {
            handlePrev()
          }
        })
        return
      }
      handlePrev()
    },

    clearTimer() {
      window.clearInterval(this.timer)
      this.timer = null
    },

    // 播放下一首
    playNext() {
      if (this.currentPlayIndex + 1 >= this.audioList.length && !this.isLoop) {
        // 无下一首了
        return
      }

      this.clearTimer()

      let handleNext = () => {
        // 已经到达列表最后一首
        if (this.currentPlayIndex + 1 >= this.audioList.length && this.isLoop) {
          this.currentPlayIndex = 0
        } else {
          this.currentPlayIndex++
        }

        this.$nextTick(() => {
          this.play()
          this.$emit('play-next')
        })
      }

      if (this.beforeNext) {
        this.beforeNext((state) => {
          if (state !== false) {
            handleNext()
          }
        })
        return
      }

      handleNext()
    },
  },
}
</script>

<style lang="scss" scoped>
	::v-deep .el-dialog__header{
		padding: 20px 20px 0;
	}
	::v-deep .el-dialog__headerbtn .el-dialog__close{
		font-size: 24px !important;
		color: #909399 !important;
	}
	::v-deep .el-dialog__body{
		padding: 14px 32px  12px !important;
	}
	::v-deep .el-dialog__headerbtn{
		top: 16px !important;
		right: 32px !important;
	}
	.dialog-header{
		.dialog-header-name{
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 18px;
			color: #333333;
			line-height: 21px;
			text-align: center;
			font-style: normal;
			text-transform: none;
			border-bottom: 1px solid #E5E5E5;
			padding-bottom: 16px;
		}
	}
	.dialogAccount-header{
		
		display: flex;
		// align-items: center;
		flex-direction: column;
		justify-content: center;
		.dialog-content-box-1{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 0;
			border-bottom: 1px solid #E5E5E5;
			.dialog-content-box-2{
				display: flex;
				flex-direction: column;
				.box-2-1{
					font-family: Source Han Sans CN, Source Han Sans CN;
					font-weight: 400;
					font-size: 16px;
					color: #333333;
					line-height: 19px;
					text-align: left;
					font-style: normal;
					text-transform: none;
				}
				.box-2-2{
					font-family: Source Han Sans CN, Source Han Sans CN;
					font-weight: 400;
					font-size: 12px;
					color: #999999;
					line-height: 14px;
					text-align: left;
					font-style: normal;
					text-transform: none;
					margin-top: 12px;
				}
			}
		}
	}
	
	
	
	.page-header-nav{
		padding: 0 12px 27px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.play-time{
			color: rgba(255, 255, 255, 0.85);
			letter-spacing: 1px;
			.time-1{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 14px;
				color: #10B1A7;
				line-height: 16px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				padding-right: 2px;
			}
			.time-2{
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 14px;
				color: rgba(255, 255, 255, 0.85);
				line-height: 16px;
				text-align: left;
				font-style: normal;
				text-transform: none;
				padding-left: 2px;
			}
		}
		.play-but{
			display: flex;
			justify-content: flex-end;
			.play-but-1{
				width: 32px;
				height: 32px;
				margin-left: 22px;
				cursor: pointer;
				img{
					width: 32px;
					height: 32px;
				}
			}
		}
	}
	
	
	
	
	
	.audio__play-rate__dropdown::-webkit-scrollbar {
	     width: 0;
	     height: 0;
	 }
	 .audio__play-rate__dropdown::-webkit-scrollbar-thumb {
	      border-radius: 10px;
		  height: 20px;
	      background: #D9D9D9;
	}
	.audio__play-rate__dropdown::-webkit-scrollbar-track {
	      border-radius: 0;
	      box-shadow: inset 0 0 5px #FFFFFF;
	      background: #FFFFFF;
	}
	.audio__play-rate__dropdown:hover::-webkit-scrollbar {
	     width: 4px;
	     height: 4px;
	 }
	.audio-footer{
		display: flex;
		justify-content: space-between;
		margin-top: 25px;
		.audio-speed{
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: #333333;
			line-height: 19px;
			text-align: right;
			font-style: normal;
			text-transform: none;
			cursor: pointer;
			display: flex;
			align-items: center;
			
			position: relative;
			img{
				width: 24px;
				height: 24px;
				margin-right: 2px;
			}
		}
		.audio-play-box{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			.audio-play-1{
				width: 24px;
				height: 24px;
				margin-right: 60px;
				cursor: pointer;
				img{
					width: 24px;
					height: 24px;
				}
			}
			.audio-play-2{
				width: 48px;
				height: 48px;
				cursor: pointer;
				img{
					width: 48px;
					height: 48px;
				}
			}
			.audio-play-3{
				width: 24px;
				height: 24px;
				margin-left: 60px;
				cursor: pointer;
				img{
					width: 24px;
					height: 24px;
				}
			}
		}
		.audio-play-sign{
			display: flex;
			align-items: center;
			.audio-play-sign-1{
				padding-right: 20px;
				border-right: 1px solid #E5E5E5;
				cursor: pointer;
				img{
					width: 24px;
					height: 24px;
				}
			}
			.audio-play-sign-2{
				width: 24px;
				height: 24px;
				padding-left: 20px;
				cursor: pointer;
				position: relative;
				.audio-play-sign-2-count{
					position: absolute;
					right: -20px;
					top: -15px;
					color: #10B1A7;
				}
				img{
					width: 24px;
					height: 24px;
				}
			}
		}
	}
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
@keyframes fadeVolume {
  from {
    height: 0;
  }
  to {
    height: 50px;
  }
}

@keyframes fadeRate {
  from {
    max-height: 0;
  }
  to {
    max-height: 120px;
  }
}

.fade-volume-enter-active {
  animation: fadeVolume 0.3s;
}

.fade-volume-leave-active {
  animation: fadeVolume 0.3s reverse;
}

.fade-rate-enter-active {
  animation: fadeRate 0.3s;
}

.fade-rate-leave-active {
  animation: fadeRate 0.3s reverse;
}

.audio-player .audio__btn-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-player .audio__play-icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
  overflow: hidden;
}

.audio-player .audio__play-volume-icon-wrap {
  position: relative;
  width: 21px;
  height: 21px;
  cursor: pointer;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  margin-left: 16px;
}

.audio-player .audio__play-volume-icon-wrap .audio__play-volume-wrap {
  position: absolute;
  width: 14px;
  height: 50px;
  bottom: 21px;
  left: 0;
  background-color: #E5E5E5;
  border-radius: 10px;
}

.audio-player
  .audio__play-volume-icon-wrap
  .audio__play-volume-wrap
  .audio__play-volume {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
}

.audio-player .audio__play-rate {
  position: relative;
  height: 21px;
  line-height: 21px;
  cursor: pointer;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  font-size: 14px;
  margin-right: 16px;
}

.audio__play-rate__dropdown {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 9px 10px;
  color: #333333;
  border-radius: 4px;
  font-size: 14px;
  overflow: hidden;
  // width: 106px;
  // height: 221px;
  background: #FFFFFF;
  //box-shadow: 0px 4px 32px 0px rgba(0,0,0,0.15);
  border-radius: 4px 4px 4px 4px;
  // margin-bottom: 20px;
  .dropdown-view{
	  padding: 10px 20px;
	  display: flex;
	  align-items: center;
	  cursor: pointer;
  }
  .dropdown-active{
	  color: #10B1A7;
  }
}

.audio-player .audio__play-prev {
  cursor: pointer;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
}

.audio-player .audio__play-prev svg {
  display: block;
  width: 21px;
  height: 33px;
}

.audio-player .audio__play-prev.disable {
  opacity: 0.5;
}

.audio-player .audio__play-start {
  margin: 0 16px;
  cursor: pointer;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
}

.audio-player .audio__play-start svg {
  display: block;
  width: 42px;
  height: 42px;
}

.audio-player .audio__play-pause {
  margin: 0 16px;
  cursor: pointer;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
}

.audio-player .audio__play-pause svg {
  display: block;
  width: 42px;
  height: 42px;
}

.audio-player .audio__play-next {
  cursor: pointer;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
}

.audio-player .audio__play-next svg {
  display: block;
  width: 21px;
  height: 33px;
}

.audio-player .audio__play-next.disable {
  opacity: 0.5;
}

.audio__notice {
  position: absolute;
  bottom: -15px;
  color: rgb(189, 178, 178);
  border-radius: 4px;
  font-size: 12px;
}

.audio__progress-wrap {
  width: 100%;
  position: relative;
  background: #ddd;
  height: 4px;
  border-radius: 3px;
  margin-top: 20px;
  cursor: pointer;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
}

.audio-player .audio__progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  border-radius: 3px;
}

.audio-player .audio__progress-point {
  position: absolute;
  left: -8px;
  top: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: -10px;
}
.audio-player .audio__progress-point-Sign{
	position: absolute;
	left: -8px;
	top: 50%;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-top: -8px;
}
.audio-player .audio__progress-point:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin: -3px 0 0 -3px;
  background: #fff;
  border-radius: 50%;
}

.audio-player .audio__time-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 7px;
}

.audio-player .audio__current-time {
  font-size: 10px;
  color: #888;
  margin-right: 18px;
  margin-top: 20px;
}

.audio-player .audio__duration {
  font-size: 10px;
  color: #888;
  margin-left: 18px;
    margin-top: 20px;
}

.audio-player .audio-player__audio {
  display: block;
  margin: 0 auto;
}

@media (any-hover: hover) {
  .audio-player .audio__play-rate:hover > span {
    opacity: 0.7;
  }

  .audio-player .audio__play-rate__dropdown > div:hover,
  .audio__play-icon:hover {
    opacity: 0.7;
  }
}

.audio__play-loading {
  width: 42px;
  height: 42px;
  position: relative;
  margin: 0 16px;
}

.audio__play-loading span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  animation: loading 1.04s ease infinite;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.audio__play-loading span:nth-child(1) {
  left: 0;
  top: 50%;
  margin-top: -4px;
  animation-delay: 0.13s;
}
.audio__play-loading span:nth-child(2) {
  left: 7px;
  top: 7px;
  animation-delay: 0.26s;
}
.audio__play-loading span:nth-child(3) {
  left: 50%;
  top: 0;
  margin-left: -4px;
  animation-delay: 0.39s;
}
.audio__play-loading span:nth-child(4) {
  top: 7px;
  right: 7px;
  animation-delay: 0.52s;
}
.audio__play-loading span:nth-child(5) {
  right: 0;
  top: 50%;
  margin-top: -4px;
  animation-delay: 0.65s;
}
.audio__play-loading span:nth-child(6) {
  right: 7px;
  bottom: 7px;
  animation-delay: 0.78s;
}
.audio__play-loading span:nth-child(7) {
  bottom: 0;
  left: 50%;
  margin-left: -4px;
  animation-delay: 0.91s;
}
.audio__play-loading span:nth-child(8) {
  bottom: 7px;
  left: 7px;
  animation-delay: 1.04s;
}

::v-deep .el-slider__button-wrapper{
	top: -16px !important;
}
/* 覆盖滑块背景色 */
::v-deep .el-slider-view .el-slider__runway {
	height: 4px !important;
	background-color: #d3dce6;
}
 
/* 覆盖滑块颜色 */
::v-deep .el-slider-view .el-slider__bar {
	height: 4px !important;
    background-color: #10B1A7 !important;
}
 
/* 覆盖滑块hover效果颜色 */
::v-deep .el-slider-view .el-slider__bar:hover {
	height: 4px !important;
    background-color: #10B1A7 !important;
}
 
/* 覆盖滑块悬停/按下效果颜色 */
::v-deep .el-slider-view .el-slider__button {
  background-color: #10B1A7 !important;
  border: 2px solid #10B1A7 !important;
  border-color: #10B1A7 !important;
  border: 2px solid #10B1A7 !important;
  box-shadow: -5px 0 10px 0 rgba(64, 158, 255, 0.5);
}
 
/* 覆盖已选区域颜色 */
::v-deep .el-slider-view .el-slider__stop {
  background-color: #10B1A7 !important;
  border-color: #10B1A7 !important;
}
</style>
