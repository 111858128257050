import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/share',///:lang?
	},
	{
		path: '/share',///:lang?
		name: 'Share',
		component: () => import('../views/share.vue')
	},
	{
		path: '/sharePage',///:lang?
		name: 'SharePage',
		component: () => import('../views/sharePage.vue')
	},
	{
		path: '/shareAudio1',///:lang?
		name: 'shareAudio1',
		component: () => import('../views/shareAudio1.vue')
	},
	{
		path: '/shareAudio2',///:lang?
		name: 'shareAudio2',
		component: () => import('../views/shareAudio2.vue')
	},
	{
		path: '/shareAudio3',///:lang?
		name: 'shareAudio3',
		component: () => import('../views/shareAudio3.vue')
	},

]

const router = new VueRouter({
	mode: 'history',
	base: '/app/', // 确保与Nginx配置中的子目录一致
	routes
})

router.beforeEach((to, from, next) => {
	const lang = to.params.lang;
	if (lang) {
		let langlocale = (lang === 'en' ? 'en' : 'zh');
		router.app.$options.i18n.locale = langlocale;
		localStorage.setItem('lang', langlocale);
		next();
	} else {
		next();
	}
});

//解决点击重复的path报错问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	//console.log("路由信息",to);
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	return VueRouterPush.call(this, to).catch(err => err)
}
export default router