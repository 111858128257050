import { getRequest , postRequest ,sendConcurrentRequests} from "../common/request";
import { baseUrl } from "../common/baseUrl";


export const getAudioDetail = (params) => {
	return postRequest(baseUrl + '/share/audio/detail', params)
}

export const getAudioText = (params) => {
	return postRequest(baseUrl + '/share/audio/text/list2', params)
}

export const getAudioTextPage = (params) => {
	return postRequest(baseUrl + '/share/audio/text/list', params)
}

//音频文本
export const getAudioTextList2 = (id,params) => {
	return sendConcurrentRequests(baseUrl + '/share/audio/text/list',id, params)
}